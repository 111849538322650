import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import UAParser from "ua-parser-js"

const HeadMeta = ({ pageTitle, children }) => {
  const render = ({ site }) => {
    const pageTitleFinalised = [
      pageTitle,
      site.siteMetadata.title,
      site.siteMetadata.orgName,
    ]
      .filter(x => x && x.length)
      .join(" | ")
    const ua = new UAParser().getResult()
    const bodyAttributes = ua
      ? {
          "data-ua-browser-name": ua.browser.name || "",
          "data-ua-browser-version": ua.browser.major || "",
          "data-ua-os-name": ua.os.name || "",
          "data-ua-os-version": ua.os.version || "",
        }
      : {}

    return (
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        bodyAttributes={bodyAttributes}
      >
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,initial-scale=1"
        />
        <title>{pageTitleFinalised}</title>

        <link
          href={`${site.siteMetadata.siteUrl}/favicon.ico`}
          type="image/ico"
          rel="icon"
        />
        <link
          href={`${site.siteMetadata.siteUrl}/favicon.ico`}
          type="image/ico"
          rel="shortcut icon"
        />

        <link rel="schema.DC" href="http://purl.org/DC/elements/1.1/" />
        <link rel="schema.AGLS" href="http://www.agls.gov.au/agls/1.2" />

        <meta name="DCTERMS.title" content={site.siteMetadata.title} />
        <meta name="DCTERMS.Creator" content={site.siteMetadata.fullOrgName} />
        <meta
          name="DCTERMS.Publisher"
          content={site.siteMetadata.fullOrgName}
        />
        <meta
          name="DCTERMS.rights"
          content={`Copyright ${site.siteMetadata.fullOrgName} 2010`}
        />
        <meta name="DCTERMS.LCNA" content={site.siteMetadata.fullOrgName} />
        <meta name="DCTERMS.LCNA" content="State libraries--New South Wales" />
        <meta name="DCTERMS.LCSH" content="Multilingual communication" />
        <meta
          name="DCTERMS.language"
          scheme="DCTERMS.RFC5646"
          content="en-AU"
        />
        <meta name="DCTERMS.date" content="2007/" />
        <meta name="DCTERMS.format" content="text/html" />
        <meta
          name="DCTERMS.date.modified"
          scheme="DCTERMS.ISO8601"
          content={site.siteMetadata.lastModified}
        />

        <meta name="description" content={site.siteMetadata.description} />
        <meta
          name="DCTERMS.description"
          content={site.siteMetadata.description}
        />

        <meta name="keywords" content={site.siteMetadata.keywords} />
        <meta name="DCTERMS.subject" content={site.siteMetadata.keywords} />
        <meta name="theme-color" content="#000000" />

        <link rel="preconnect" href="https://cloud.typography.com" />
        <link rel="preconnect" href="https://www.sl.nsw.gov.au" />
        <link
          rel="preconnect"
          href="https://www.google-analytics.com"
          crossOrigin="anonymous"
        />

        {/* The following metatags are handled by Gatsby or its plugins: canonical, viewport, note, X-UA-Compatible */}
        {children}
      </Helmet>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query HeadMetaQuery {
          site {
            siteMetadata {
              title
              orgName
              fullOrgName
              description
              keywords
              siteUrl
              baseAssetUrl
              lastModified(formatString: "YYYY-MM-DD")
            }
          }
        }
      `}
      render={render}
    />
  )
}

export default HeadMeta
