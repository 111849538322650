import * as React from "react"
import variables from "../styles/global.scss"
import { Lunr } from './types';

/**
 * Get window size
 */
export function useWindowSize(width: number = 800, height: number = 600) {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : width,
      height: isClient ? window.innerHeight : height,
    }
  }

  const [windowSize, setWindowSize] = React.useState(getSize)

  React.useEffect(() => {
    /**
     * Handle resize events.
     *
     * Since Safari will sometimes fire resize events during a scroll,
     * check to make sure that the window size has actually changed.
     */
    function handleResize() {
      const currentSize = getSize()
      if (
        currentSize.width !== windowSize.width ||
        currentSize.height !== windowSize.height
      ) {
        setWindowSize(currentSize)
      }
    }

    if (isClient) {
      window.addEventListener("resize", handleResize)
    }
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

export function useMediaQuery() {
  const { width } = useWindowSize()

  const baseline = parseInt(variables.baseline, 10) || 8
  const xs = parseInt(variables["mediaXs"], 10) * baseline * 2
  const sm = parseInt(variables["mediaSm"], 10) * baseline * 2
  const md = parseInt(variables["mediaMd"], 10) * baseline * 2
  const lg = parseInt(variables["mediaLg"], 10) * baseline * 2
  const xlg = parseInt(variables["mediaXlg"], 10) * baseline * 2

  let mediaQuery = "xxs"

  if (width >= xs && width < sm) {
    mediaQuery = "xs"
  } else if (width >= sm && width < md) {
    mediaQuery = "sm"
  } else if (width >= md && width < lg) {
    mediaQuery = "md"
  } else if (width >= lg && width < xlg) {
    mediaQuery = "lg"
  } else if (width >= xlg) {
    mediaQuery = "xlg"
  }

  return mediaQuery
}

export function useHasScrollbar() {
  const { width, height } = useWindowSize()

  if (typeof document !== "undefined") {
    return (
      document.body.scrollHeight > height && document.body.offsetWidth < width
    )
  }
  return false
}

export function useLunr(languageCode: string = 'en', debug: boolean = false) {
  const [searchReady, setSearchReady] = React.useState(false);

  const getLunr = async (): Promise<Lunr> => {
    const setStatePromise = (): Promise<void> => {
      return new Promise((resolve) => {
        setSearchReady(false);
        resolve();
      });
    };

    const getSearchPromise = (): Promise<Lunr> => {
      return new Promise((resolve, reject) => {
        const limit = 20
        let attempts = 0
        const interval = setInterval(() => {
          if (
            window.__LUNR__ &&
            typeof window.__LUNR__[languageCode] === "object"
          ) {
            clearInterval(interval)
            setSearchReady(true);
            resolve(window.__LUNR__[languageCode])
          }
          attempts += 1
          if (attempts >= limit) {
            clearInterval(interval)
            reject()
          }
        }, 100)
      });
    };
    await setStatePromise();
    return getSearchPromise();
  };

  const waitForLunr = async (): Promise<Lunr> => {
    if (searchReady) {
      return Promise.resolve(window.__LUNR__[languageCode]);
    }
    return getLunr();
  };

  React.useEffect(() => {
    getLunr();
  }, [languageCode]);

  const runSearch = async (currentQuery: string): Promise<any[]> => {
    if (!currentQuery || !currentQuery.length) {
      return []
    }
    let lunr: Lunr
    try {
      lunr = await waitForLunr()
    } catch (e) {
      console.error("No LUNR", e)
      return []
    }
    const results = lunr.index.search(currentQuery)
    const mapped = results.map(({ ref }) => lunr.store[ref]);
    if (debug) {
      console.log(currentQuery, results, mapped);
    }
    return mapped
  }

  const runComplexSearch = async (fields: any = {}, options: any = {}): Promise<any[]> => {
    const chunks = [];
    Object.keys(fields).forEach(key => {
      if (typeof fields[key] === 'undefined' || fields[key] === null || fields[key].length === 0) {
        return;
      }
      const words = fields[key].toString()
        .split(/\s+/).filter(x => x && x.length);
      if (key === 'q') {
        words.forEach(w => chunks.push(`+${w}`));
      } else {
        words.forEach(w => chunks.push(`+${key}:${w}`));
      }
    });
    return runSearch(chunks.join(' '));
  };

  return [searchReady, runSearch, runComplexSearch];
}
