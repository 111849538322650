import * as React from "react"

import Button from "../Button"
import SVGIcon, { SVGIconSize } from "../SVGIcon"

import css from "./IconButton.module.scss"

import { SVGIconName } from "../SVGIcon"

type Props = React.ComponentPropsWithRef<any> & {
  iconName: SVGIconName
  iconVariant?: "black" | "white" | "grey"
  size?: SVGIconSize
  children?: React.ReactNode
  as?: string
  href?: string
  className?: string
  ariaLabel?: string
  onClick?: Function
}

const IconButton: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      iconName,
      iconVariant = "black",
      size,
      children,
      className,
      as,
      href,
      ariaLabel = iconName,
      ...restProps
    },
    ref
  ) => {
    const hasChildren = Boolean(children)

    const contained: React.ReactNode = (
      <>
        <SVGIcon
          name={iconName}
          size={size || (hasChildren ? "sm" : "md")}
          variant={iconVariant}
          className={css.icon}
        />
        {children}
      </>
    );

    if (as) {
      return React.createElement(as, {
        className: [css.iconButton, className || ""].join(" "),
        ...restProps,
        href,
        'aria-label': hasChildren ? undefined : ariaLabel,
        ref,
      }, contained);
    }

    return (
      <Button
        className={[css.iconButton, className || ""].join(" ")}
        {...restProps}
        aria-label={hasChildren ? undefined : ariaLabel}
        ref={ref as React.RefObject<HTMLButtonElement>}
      >
        {contained}
      </Button>
    )
  }
)

export default IconButton
