import * as React from "react"

import { getSVG } from "./getSVG"

import css from "./SVGIcon.module.scss"

/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['xs', 'sm', 'md', 'lg','white', 'grey'] }] */
/* eslint css-modules/no-undef-class: 0 */

export type SVGIconSize = "xs" | "sm" | "md" | "lg";

type Props = {
  name: SVGIconName
  size?: SVGIconSize
  weight?: "thin" | "regular"
  variant?: "black" | "white" | "grey"
  className?: string
}

export type SVGIconName =
  | "books"
  | "manuscripts"
  | "maps"
  | "mixed"
  | "minimise"
  | "maximise"
  | "pictures"
  | "audio"
  | "eresources"
  | "journalsMagazines"
  | "music"
  | "newspapers"
  | "objects"
  | "videoFilms"
  | "grid"
  | "list"
  | "close"
  | "hierarchyBrowser"
  | "up"
  | "down"
  | "left"
  | "right"
  | "search"
  | "fullScreen"
  | "exitFullScreen"
  | "zoomIn"
  | "zoomOut"
  | "fitScreen"
  | "rotate"
  | "album"
  | "update"
  | "filter"
  | "check"
  | "hamburger"
  | "facebook"
  | "twitter"
  | "youtube"
  | "instagram"
  | "pinterest"
  | "flickr"
  | "historyPin"
  | "link"
  | "share"
  | "download"
  | "maximiseCircle"
  | "imagesKey"

const SVGIcon: React.FunctionComponent<Props> = ({
  name = "mixed",
  size,
  weight = "regular",
  variant = "black",
  className,
}) => {
  return (
    <span
      className={[css.svgIcon, css[size], css[variant], className || ""].join(
        " "
      )}
    >
      <svg
        className={css.svg}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {getSVG(`${name}${weight === "thin" ? "-thin" : ""}`)}
      </svg>
    </span>
  )
}

export default SVGIcon
