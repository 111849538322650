import * as React from "react"

import css from "./Button.module.scss"

/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['sm', 'dark'] }] */

export type ButtonProps = React.ComponentPropsWithRef<any> & {
  size?: "sm" | "md" | "lg"
  variant?: "light" | "dark" | "grey"
  disabled?: boolean
  type?: "button" | "submit" | "reset"
  className?: string
  children?: React.ReactNode
  onClick?: Function
}

// React.forwardRef added because of:
// Warning: Function components cannot be given refs. Attempts to access
// this ref will fail. Did you mean to use React.forwardRef()? Check the
// render method of `Link`.
// https://github.com/zeit/next.js/issues/7915
const Button: React.FunctionComponent<ButtonProps> = React.forwardRef(
  (
    {
      size = "md",
      variant = "light",
      disabled = false,
      type,
      children,
      className,
      ...restProps
    },
    ref
  ) => (
    <button
      type={type}
      className={[
        css.button,
        size !== "md" ? css[size] : "",
        variant !== "light" ? css[variant] : "",
        className || "",
      ].join(" ")}
      disabled={disabled}
      {...restProps}
      ref={ref}
    >
      {children}
    </button>
  )
)

export default Button
