export default [
  {
    name: "Home",
    path: "/",
    neverContain: true,
  },
  {
    name: "NSW Parliamentarians",
    path: "/parliamentarians/",
    canContain: [/^\/parliamentarians\/.+/],
  },
  {
    name: "NSW Electorates",
    path: "/electorates/",
    canContain: [/^\/parliamentarians\/.+/],
  },
  {
    name: "Politicial Parties",
    path: "/parties/",
    canContain: [/^\/party\/.+/],
  },
  {
    name: "Project Overview",
    path: "/project-overview/",
  },
  {
    name: "Resources and References",
    path: "/resources/",
  },
  {
    name: "Enquiries",
    path: "/enquiries/",
  },
  {
    name: "Search",
    path: "/search/",
    children: [
      {
        name: "Advanced Search",
        path: "/search/advanced/",
      },
    ],
  },
]
