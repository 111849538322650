import * as React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"

const getSearch = (location: any): any => {
  const search = location.search || '';
  if ((search || '').length > 0) {
    return queryString.parse(search.replace(/^\?/, ""));
  }
  return {};
};

const withLocation = <P extends object>(ComponentToWrap: React.ComponentType<P>) => (props: P) => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={getSearch(location)}
      />
    )}
  </Location>
)

export default withLocation